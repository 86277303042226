/* default theme colors if not specified */
:root {
  --theme-primary: #2f2d60;
  --theme-bg: #2f2d60;
  --theme-title: #ffffff;
  --shadow-color: 0deg 0% 63%;
  --shadow-elevation-low: 0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.34),
    0.4px 0.8px 1px -1.2px hsl(var(--shadow-color) / 0.34),
    1px 2px 2.5px -2.5px hsl(var(--shadow-color) / 0.34);
  --shadow-elevation-medium: 0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.36),
    0.8px 1.6px 2px -0.8px hsl(var(--shadow-color) / 0.36),
    2.1px 4.1px 5.2px -1.7px hsl(var(--shadow-color) / 0.36),
    5px 10px 12.6px -2.5px hsl(var(--shadow-color) / 0.36);
  --shadow-elevation-high: 0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.34),
    1.5px 2.9px 3.7px -0.4px hsl(var(--shadow-color) / 0.34),
    2.7px 5.4px 6.8px -0.7px hsl(var(--shadow-color) / 0.34),
    4.5px 8.9px 11.2px -1.1px hsl(var(--shadow-color) / 0.34),
    7.1px 14.3px 18px -1.4px hsl(var(--shadow-color) / 0.34),
    11.2px 22.3px 28.1px -1.8px hsl(var(--shadow-color) / 0.34),
    17px 33.9px 42.7px -2.1px hsl(var(--shadow-color) / 0.34),
    25px 50px 62.9px -2.5px hsl(var(--shadow-color) / 0.34);
}

html.tw-dark {
  --theme-primary: #e3e3e3;
  --theme-bg: #2f2d60;
  --theme-title: #ffffff;
}

*,
::before,
::after {
  box-sizing: border-box;
}

html {
  font-size: 100%;
}

body {
  margin: 0;
  font-family: 'Phantom Sans 0.6', 'Roboto', system-ui,
    /* macOS 10.11-10.12 */ -apple-system, /* Windows 6+ */ 'Segoe UI',
    /* Android 4+ */ 'Roboto', /* Ubuntu 10.10+ */ 'Ubuntu',
    /* Gnome 3+ */ 'Cantarell', /* KDE Plasma 5+ */ 'Noto Sans',
    /* fallback */ sans-serif, /* macOS emoji */ 'Apple Color Emoji',
    /* Windows emoji */ 'Segoe UI Emoji', /* Windows emoji */ 'Segoe UI Symbol',
    /* Linux emoji */ 'Noto Color Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
}

@font-face {
  font-family: 'Phantom Sans 0.6';
  font-style: 350;
  src: url('/@venue/assets/fonts/PhantomSans0.6-Book.woff2') format('woff2'),
    url('/@venue/assets/fonts/PhantomSans0.6-Book.woff') format('woff');
}

@font-face {
  font-family: 'Phantom Sans 0.6';
  font-weight: 400;
  src: url('/@venue/assets/fonts/PhantomSans0.6-Reg.woff2') format('woff2'),
    url('/@venue/assets/fonts/PhantomSans0.6-Reg.woff') format('woff');
}

@font-face {
  font-family: 'Phantom Sans 0.6';
  font-weight: 500;
  src: url('/@venue/assets/fonts/PhantomSans0.6-Med.woff2') format('woff2'),
    url('/@venue/assets/fonts/PhantomSans0.6-Med.woff') format('woff');
}

@font-face {
  font-family: 'Phantom Sans 0.6';
  font-weight: 600;
  src: url('/@venue/assets/fonts/PhantomSans0.6-Semibold.woff2') format('woff2'),
    url('/@venue/assets/fonts/PhantomSans0.6-Semibold.woff') format('woff');
}

@font-face {
  font-family: 'Phantom Sans 0.6';
  font-weight: 700;
  src: url('/@venue/assets/fonts/PhantomSans0.6-Bold.woff2') format('woff2'),
    url('/@venue/assets/fonts/PhantomSans0.6-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Phantom Sans 0.6';
  font-style: italic;
  src: url('/@venue/assets/fonts/PhantomSans0.6-Italic.woff2') format('woff2'),
    url('/@venue/assets/fonts/PhantomSans0.6-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Phantom Sans 0.6';
  font-style: italic;
  src: url('/@venue/assets/fonts/PhantomSans0.6-Italic.woff2') format('woff2'),
    url('/@venue/assets/fonts/PhantomSans0.6-Italic.woff') format('woff');
}

@font-face {
  font-family: 'GT America Regular';
  font-weight: 400;
  src: url('/@venue/assets/fonts/gt-america-regular.woff2') format('woff2'),
    url('/@venue/assets/fonts/gt-america-regular.woff') format('woff');
}

@font-face {
  font-family: 'GT America Bold';
  font-weight: 700;
  src: url('/@venue/assets/fonts/gt-america-bold.woff2') format('woff2'),
    url('/@venue/assets/fonts/gt-america-bold.woff') format('woff');
}

/**
 * Use the default monospace user interface font in all browsers (opinionated).
 */

code,
kbd,
samp,
pre {
  font-family:
     /* macOS 10.10+ */ 'Menlo', /* Windows 6+ */ 'Consolas',
    /* Android 4+ */ 'Roboto Mono', /* Ubuntu 10.10+ */ 'Ubuntu Monospace',
    /* KDE Plasma 5+ */ 'Noto Mono', /* KDE Plasma 4+ */ 'Oxygen Mono',
    /* Linux/OpenOffice fallback */ 'Liberation Mono', /* fallback */ monospace,
    /* macOS emoji */ 'Apple Color Emoji', /* Windows emoji */ 'Segoe UI Emoji',
    /* Windows emoji */ 'Segoe UI Symbol', /* Linux emoji */ 'Noto Color Emoji';
}

input,
button,
select,
textarea,
optgroup,
option {
  font-family: inherit;
}
